import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
   providedIn: 'root',
})
export class TranslateStateService {
   private langSource = new BehaviorSubject<any>(null)
   langValue = this.langSource.asObservable()

   constructor() {}

   storeValue(value: any) {
      this.langSource.next(value)
   }
}
