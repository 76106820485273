<nav class="side-bar" [class.side-bar--active]="active">
   <div class="side-bar__picture">
      <img
         *ngIf="active"
         class="side-bar__picture-logo"
         src="assets/imgs/bw-new-2.png"
         alt="Logobluewhale-admin.com.br"
         aria-label="Logo bluewhale-admin.com.br"
         [class.side-bar__logo--closed]="!active"
         routerLink="/home"
      />
   </div>
   <ul class="side-bar__menu">
      <ng-container *ngFor="let route of routers; let i = index">
         <li
            *ngIf="route.enable"
            class="side-bar__menu__item"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [class.side-bar__menu__item--auto-height]="route.collapseMenu"
         >
            <div
               (click)="activeSubmenu(route)"
               [routerLink]="route.route"
               class="side-bar__menu__item__content"
            >
               <div
                  class="side-bar__menu__item__content-box"
                  [class.side-bar__menu__item__content-box--closed]="!active"
               >
                  <span [class]="route.icon"></span>
                  <p
                     routerLinkActive="active"
                     [routerLinkActiveOptions]="{ exact: true }"
                     *ngIf="active"
                  >
                     {{ route.title }}
                  </p>
               </div>
               <arrow
                  *ngIf="route.subRouting"
                  class="side-bar__menu__item__content-arrow"
                  [type]="'bottom'"
                  [active]="route.collapseMenu"
               ></arrow>
            </div>
            <ul
               class="side-bar__menu__item__sub-nav"
               *ngIf="route.subRouting && route.collapseMenu"
            >
               <li class="side-bar__menu__item__sub-nav-link" *ngFor="let item of route.subRouting">
                  <a [routerLink]="item.path" routerLinkActive="active">{{ item.name }}</a>
               </li>
            </ul>
         </li>
      </ng-container>
   </ul>
   <div class="side-bar__footer" [class.side-bar__footer--closed]="!active">
      <ng-container>
         <span class="side-bar__footer-user">
            <button
               class="side-bar__footer-user-profile"
               (click)="openCropper()"
               [class.side-bar__footer-user-profile--img]="!!profileImg"
            >
               <img
                  *ngIf="profileImg; else avatarImg"
                  class="img-avatar"
                  width="40px"
                  [src]="profileImg"
                  alt=""
               />
               <ng-template #avatarImg>
                  <img
                     src="assets/imgs/avatar.svg"
                     class="img-avatar"
                     [class.img-avatar--closed]="!active"
                     width="'32px'"
                  />
               </ng-template>
            </button>
         </span>
      </ng-container>

      <button class="side-bar__footer-logout" (click)="logOut()" id="logout">
         <span class="icon-exit"></span> {{ 'SIDEMENU.OUT' | translate }}
      </button>
   </div>
</nav>
