import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent {
  @ViewChild('calendarOnlyTime') calendarOnlyTime: Calendar;

  @Input() label: string = 'Selecione uma data';

  @Input() form: FormGroup;

  @Input() controlName: string;

  @Input() calendarType: 'noTime' | 'hasTime' | 'onlyTime' = 'noTime';

  @Input() minDate: any;

  @Input() maxDate: any;

  constructor() {}

  get control() {
    return this.form.get(this.controlName) as FormControl;
  }

  onDateSelect(value: any): void {
    const formattedDateControl = moment(value).format("MM/DD/YYYY");
    this.form.get(this.controlName)?.setValue(formattedDateControl);
  }

  onDateWithTimeSelect(value: Date): void {}
  
  setTime() {
    let selectedTime = this.control.value;
    if (!selectedTime) {
      selectedTime = new Date();
    }
    this.form.get(this.controlName)?.setValue(selectedTime);
    this.calendarOnlyTime.overlayVisible = false;
  }

  get isRequiredInvalid(): boolean {
    return this.control && this.control.errors && this.control.errors['required'] && this.control.touched;
  }
}
