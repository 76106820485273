import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { VoidInterface } from '@interfaces/generic.interface';
import { UserPhoneInterface, UsersInterfaceRequest, UsersInterfaceResponse } from '@interfaces/users.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly apiUrl = environment.apiUrl;
  
  constructor(private httpClient: HttpClient) { }

  public getUsers(): Observable<UsersInterfaceResponse> {
    return this.httpClient.get<UsersInterfaceResponse>(`${this.apiUrl}users/client/get`);
  }

  public postUsers(payload: UsersInterfaceRequest): Observable<VoidInterface> {
    return this.httpClient.post<VoidInterface>(`${this.apiUrl}users/client/create`, payload);
  }

  public deleteUsers(idUser: string): Observable<VoidInterface> {
    return this.httpClient.delete<VoidInterface>(`${this.apiUrl}users/client/delete/${idUser}`);
  }

  public getUserByPhone(userNumber: string): Observable<UserPhoneInterface> {
    return this.httpClient.get<UserPhoneInterface>(`${this.apiUrl}users/client/get/phone/${userNumber}`)
  }

  public patchAssociateUserClient(id: string, voidPayload: Object = {}): Observable<VoidInterface> {
    return this.httpClient.patch<VoidInterface>(`${this.apiUrl}users/client/associate/${id}`, voidPayload)
  }

  public putClientSuspend(id: string, {}: VoidInterface): Observable<String> {
    return this.httpClient.put<String>(`${this.apiUrl}users/client/suspend/${id}`, {})
  }
}