<form [formGroup]="form">
   <label for="">{{ label }}</label>
   <ckeditor
      tagName="textarea"
      class="editor"
      [editor]="Editor"
      [class.maxtext]="charactersTextarea.length === limitCharacters"
      [config]="config"
      height="400px"
      (ready)="onReady($event)"
      [(ngModel)]="charactersTextarea"
      [maxlength]="limitCharacters"
      [formControlName]="inputControlName"
   ></ckeditor>
</form>
