import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { UrlResponseMuxInterface, StorybookVideoInterface, VideoPayloadInterface } from '@interfaces/video-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideosService {
  private readonly apiUrl = environment.apiUrl;
  
  constructor(private httpClient: HttpClient) { }

  public getVideos(): Observable<StorybookVideoInterface> {
    return this.httpClient.get<StorybookVideoInterface>(`${this.apiUrl}videos/client/get`);
  }
  
  public generateUrl(): Observable<UrlResponseMuxInterface> {
    return this.httpClient.get<UrlResponseMuxInterface>(`${this.apiUrl}videos/client/generateUploadUrl`);
  }

  public createVideo(payloadVideo: VideoPayloadInterface): Observable<VideoPayloadInterface> {
    return this.httpClient.post<VideoPayloadInterface>(`${this.apiUrl}videos/client/create`, payloadVideo);
  }

}
