export interface NavMenuInterface {
   id: number
   title: string
   route: string
   icon: string
   collapseMenu: boolean
   enable: boolean
   subRouting?: SubRoutingInterface[]
}

interface SubRoutingInterface {
   name: string
   path: string
}

export const navMenuItemsPT: NavMenuInterface[] = [
   { id: 1, title: 'Painel', route: 'home', icon: 'icon-home', collapseMenu: false, enable: true },
   {
      id: 2,
      title: 'Usuários',
      route: 'users',
      icon: 'icon-users',
      collapseMenu: false,
      enable: true,
      subRouting: [{ name: 'Assinaturas', path: 'users/subscriptions' }],
   },
   {
      id: 3,
      title: 'Vídeos',
      route: 'videos',
      icon: 'icon-video',
      collapseMenu: false,
      enable: true,
      subRouting: [{ name: 'Live Streams', path: 'videos/live-stream' }],
   },
   {
      id: 4,
      title: 'Relatórios',
      route: 'reports',
      icon: 'icon-document',
      enable: false,
      collapseMenu: false,
   },
   {
      id: 5,
      title: 'Configurações',
      route: 'settings/general',
      icon: 'icon-settings',
      collapseMenu: false,
      enable: true,
      subRouting: [
         { name: 'Geral', path: 'settings/general' },
         { name: 'Negócios', path: 'settings/business' },
         { name: 'Aparência', path: 'settings/styling' },
         { name: 'Planos', path: 'settings/plans' },
         { name: 'Integrações', path: 'settings/integration' },
      ],
   },
   {
      id: 6,
      title: 'Financeiro',
      route: 'financial',
      icon: 'icon-dollar',
      enable: true,
      collapseMenu: false,
   },
]

export const navMenuItemsEN: NavMenuInterface[] = [
   {
      id: 1,
      title: 'Dashboard',
      route: 'home',
      icon: 'icon-home',
      enable: true,
      collapseMenu: false,
   },
   {
      id: 2,
      title: 'Users',
      route: 'users',
      icon: 'icon-users',
      enable: true,
      collapseMenu: false,
      subRouting: [{ name: 'Subscriptions', path: 'users/subscriptions' }],
   },
   {
      id: 2,
      title: 'Videos',
      route: 'videos',
      icon: 'icon-video',
      enable: true,
      collapseMenu: false,
      subRouting: [{ name: 'Live Streams', path: 'videos/live-stream' }],
   },
   {
      id: 4,
      title: 'Reports',
      route: 'reports/geolocalization',
      enable: false,
      icon: 'icon-document',
      collapseMenu: false,
   },
   {
      id: 5,
      title: 'Settings',
      route: 'settings/business',
      icon: 'icon-settings',
      enable: true,
      collapseMenu: false,
      subRouting: [
         { name: 'Business', path: 'settings/business' },
         { name: 'Appearance', path: 'settings/styling' },
      ],
   },
   {
      id: 6,
      title: 'Financial',
      route: 'financial',
      icon: 'icon-dollar',
      enable: true,
      collapseMenu: false,
   },
]
