import { transition, trigger } from '@angular/animations'
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import {
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core'
import * as animations from '@animations'
import { Placement } from '@enums/placementOverlay.enum'
import { POSITION_MAP } from '@helpers/constants/popover-position.helper'
import { Debounce } from '@helpers/decorators/debounce.helper'
@Component({
  selector: 'app-tooltip',
  template: `
    <div
      class="tooltip {{ tooltipPlacement }}"
      id="tooltip"
      [ngStyle]="tooltipStyles"
      *ngIf="!tooltipDisabled"
      @openAnimation
    >
      {{ text }}
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  `,
  styleUrls: ['./tooltip.directive.scss'],
  animations: [
    trigger('openAnimation', [
      transition(':enter', animations.useFadeInAnimation('0.3s')),
      transition(':leave', animations.useFadeOutAnimation('0.3s')),
    ]),
  ],
})
export class TooltipComponent {
  @Input() text!: string
  @Input() content!: TemplateRef<any>
  @Input() tooltipPlacement: Placement = 'top'
  @Input() tooltipStyles!: { [klass: string]: any }
  @Input() tooltipDisabled = false
}

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnInit {
  @Input('tooltip') content!: string | TemplateRef<any>
  @Input() tooltipPlacement: Placement = 'top'
  @Input() tooltipStyles!: { [klass: string]: any }
  @Input() tooltipDisabled = false
  @Input() tooltipClickClose = true
  private _overlayRef!: OverlayRef

  constructor(
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _overlay: Overlay,
  ) {}

  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions(this.positionStrategy)

    if (this.content) {
      this._overlayRef = this._overlay.create({ positionStrategy })
    }
  }

  get positionStrategy(): ConnectedPosition[] {
    return [POSITION_MAP[this.tooltipPlacement]]
  }

  @HostListener('mouseenter')
  show() {
    const tooltipPortal = new ComponentPortal(TooltipComponent)

    const tooltipRef: ComponentRef<TooltipComponent> = this._overlayRef?.attach(tooltipPortal)
    if (tooltipRef.instance) {
      tooltipRef.instance.tooltipPlacement = this.tooltipPlacement
      tooltipRef.instance.tooltipStyles = this.tooltipStyles
      tooltipRef.instance.tooltipDisabled = this.tooltipDisabled
      if (typeof this.content === 'string') {
        tooltipRef.instance.text = this.content
      } else {
        tooltipRef.instance.content = this.content
      }

      if (window.innerWidth <= 992) {
        this._hideWithTime()
      }
    }
  }

  @HostListener('mouseout')
  hide() {
    this._overlayRef?.detach()
  }

  @Debounce(1000)
  private _hideWithTime() {
    this.hide()
  }

  @HostListener('click')
  clickClose() {
    this.tooltipClickClose && this.hide()
  }
}
