<div class="not-found">
   <i [class]="'search-icon icon-' + icon"></i>
   <p class="not-found-title">{{ title }}</p>
   <p class="not-found-text">
      {{ text }}
   </p>
   <p class="not-found-text">
      {{ secondText }}
   </p>
</div>
