<div class="upload">
   <label
      for="imageUpload"
      [class.btn-loader--start]="isLoading"
      class="btn-loader upload-button btn-primary label-button"
   >
      <span class="text-upload">
         Escolher Imagem <i class="icon-plus" style="font-size: 16px"></i>
      </span>
      <input
         id="imageUpload"
         type="file"
         (change)="onFileChange($event)"
         style="display: none"
         accept="image/png"
      />
   </label>
   <div class="preview" *ngIf="imagePreview; else previewDefault">
      <img class="preview-img" [src]="imagePreview" alt="Preview" />
      <button type="button" class="clear-button" (click)="clearImage()">
         <i class="icon-cancel" style="color: #fff"></i>
      </button>
   </div>

   <ng-template #previewDefault>
      <div class="img-preview">
         <i style="font-size: 47px; color: #115bfb" class="icon-picture"></i>
      </div>
   </ng-template>
</div>
