/* eslint-disable sonarjs/no-duplicate-string */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "icon",
})
export class IconPipe implements PipeTransform {
   transform(value: string): string {
      let icon: string;

      switch (value) {
         case "awaitingApproval":
            icon = "warn";
            break;
         case "suspended":
            icon = "cancel-circle";
            break;
         case "disapproved":
            icon = "cancel-circle";
            break;
         case "inactive":
            icon = "cancel-circle";
            break;
         case "active":
            icon = "ok-circle";
            break;
         case "blocked":
            icon = "cancel-circle";
            break;
         case "failed":
            icon = "cancel-circle";
            break;
         case "waiting":
            icon = "warn";
            break;
         case "success":
            icon = "success-circle";
            break;

         default:
            "unknown";
            icon = "warn";
            break;
      }
      return icon;
   }
}
