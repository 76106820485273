import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { TranslateStateService } from '@shared/services/store/translate-state.service'
import { Subscription } from 'rxjs'

@Component({
   selector: 'error-message',
   templateUrl: './error-message.component.html',
   styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
   public subscriptions: Subscription[] = []
   @Input() control: AbstractControl
   @Input() msgs: { [key: string]: string } = {
      required: 'Campo obrigatório',
      minlength: '"Mínimo {number} caracteres',
      maxlength: 'Máximo {number} caracteres',
      haslength: 'Deve conter {number} caracteres',
      email: 'E-mail inválido',
      password: 'Senha Inválida',
      cpfValid: 'CPF Inválido',
      cnpjValid: 'CNPJ Inválido',
      postcodeInvalid: 'CEP Inválido',
   }
   controlStatusChangesSubscription: Subscription
   errorMessages: { label?: string; requiredLength?: any }[] = []
   constructor(private translateStore: TranslateStateService) {}

   /* istanbul ignore next */
   ngOnInit() {
      this.translateStorage()
      this.buildErrorMessage()
      this.controlStatusChangesSubscription = this.control?.statusChanges.subscribe(() =>
         this.buildErrorMessage(),
      )
   }
   ngOnDestroy(): void {
      this.controlStatusChangesSubscription?.unsubscribe()
      this.subscriptions.forEach(s => s.unsubscribe)
   }

   buildErrorMessage() {
      this.errorMessages = []
      if (this.control?.errors && this.msgs) {
         const errors = Object.keys(this.control.errors)
         errors.forEach(key => {
            this.errorMessages.push({
               label: this.msgs[key],
               // @ts-ignore: Object is possibly 'null'.
               requiredLength: this.control.errors[key]['requiredLength'],
            })
         })
      }
   }
   translateStorage() {
      this.subscriptions.push(
         this.translateStore.langValue.subscribe({
            next: (res: any) => {
               if (res === 'en') {
                  this.msgs = {
                     required: 'Required field',
                     minlength: 'Minimum {number} characters',
                     maxlength: 'Maximum {number} characters',
                     haslength: 'Must have {number} characters',
                     email: 'Invalid email',
                     password: 'Invalid password',
                     cpfValid: 'Invalid CPF (Brazilian individual taxpayer registry)',
                     cnpjValid: 'Invalid CNPJ (Brazilian company taxpayer registry)',
                     postcodeInvalid: 'Invalid ZIP code',
                  }
               }
            },
         }),
      )
   }
}
