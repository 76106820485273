import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { UsersService } from '@services/users.service';
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper';
import { DIALOG_DATA } from '@shared/services/dialog/modal.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastComponent } from '../toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VoidInterface } from '@interfaces/generic.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-generic',
  templateUrl: './dialog-generic.component.html',
  styleUrls: ['./dialog-generic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogGenericComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  public steps = 1;
  public errorData: any;
  public isLoading = false;
  public subscriptions: Subscription[] = [];

  displayAssociate() {
    this.isLoading = true;
    this.subscriptions.push(
      this.usersService
         .patchAssociateUserClient(this.data?.item?.id)
         .pipe(finalize(() => this.isLoading = false))
         .subscribe({
            next: (_: VoidInterface) => {
              this.dialogRef.close();
              const data: any = {
                title: "Sucesso",
                text: "Usuário associado com sucesso!",
                type: "success",
                icon: "ok-circle",
              };
              this.openToast(data);
              this.router.navigate(['users'])
            },
            error: (err: HttpErrorResponse) => {
              this.errorData = err;
              if (err.error.statusCode === 409) {
                this.steps = 2;
              }
            },
         }),
   );
  }

  openToast(data: any) {
    this.snackBar.openFromComponent(ToastComponent, {
       verticalPosition: 'bottom',
       data: data,
       horizontalPosition: 'right',
    })
 }

  
}
