import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { CategoryInterface } from '@interfaces/category.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private readonly apiUrl = environment.apiUrl;
  
  constructor(private httpClient: HttpClient) { }

  public getCategoriesVideos(): Observable<CategoryInterface> {
    return this.httpClient.get<CategoryInterface>(`${this.apiUrl}video-categories/client/get`);
  }
}
