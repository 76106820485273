<loading></loading>

<ng-container *ngIf="showSideBar">
   <app-header [active]="active" (toggle)="active = $event" />
   <div class="side">
      <sidemenu [browserLang]="browserLang" [navi18n]="navi18n" [active]="active" />
   </div>
</ng-container>

<main
   class="content"
   [class.content--active]="active"
   [class.content--nosidebar]="!showSideBar"
   [class.content--transition]="toggleTransition"
>
   <div class="content__body" [class.content__body--noheader]="!showSideBar">
      <router-outlet></router-outlet>
   </div>
</main>
