import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bluewhale-multi-select',
  templateUrl: './bluewhale-multi-select.component.html',
  styleUrls: ['./bluewhale-multi-select.component.scss']
})
export class BluewhaleMultiSelectComponent {

  public arrowToggle: boolean;

  @Input() form: FormGroup;

  @Input() controlName: string;

  @Input() optionsData: any;

  @Input() labelName: string;

  @Input() placeholder: string;

  get control() {
    if (!this.form || !this.controlName) {
      return null;
    }
    return this.form.get(this.controlName) as FormControl;
  }

  animatedArrow() {
    this.arrowToggle = !this.arrowToggle
  }

  get isRequiredInvalid(): boolean {
    return this.control && this.control.errors && this.control.errors['required'] && this.control.touched;
  }
}
