import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
   selector: "card",
   templateUrl: "./card.component.html",
   styleUrls: ["./card.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
   @Input() content: any;
   @Input() loading: any;
   @Input() hasReject = true;
   @Input() type: "product" | "store" | "withdraw" | "shipping";
   @Output() answer = new EventEmitter<"disapprove" | "approve">();
   constructor() { }
}
