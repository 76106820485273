import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnumsStorage } from "@enums/sessionStorage.num";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexYAxis,
  ChartType
} from "ng-apexcharts";
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ChartComponent implements OnInit {
  constructor(private translateService: TranslateService) {}

  @ViewChild("apexChart") apexChart: ChartComponent;
  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public title: ApexTitleSubtitle;
  public stroke: ApexStroke;
  public dataLabels: ApexDataLabels;
  public colors: string[];
  public fill: ApexFill;
  public legend: ApexLegend;
  public grid: ApexGrid;
  public yAxis: ApexYAxis;
  public labels: string[];
  public tooltip: ApexTooltip;

  @Input() typeChart: ChartType = 'area';
  @Input() label: string = 'Default';

  public i18nMonthsShort: any;

  ngOnInit(): void {
    this.validationLangAndSettingMonths();
    this.initializeChart();
  }

  //TODO AJUSTAR O GRADIENTE AZUL O SELECT DO ANO E AS LEGENDAS NO TOPO, E ATUALIZAR A LARGURA QUANDO FECHA A SIDEBAR


  validationLangAndSettingMonths() {
    const langSelected = JSON.parse(
      sessionStorage.getItem(EnumsStorage.USER_LANG) || "{}"
    );
    const validationLangBrowser = Object.keys(langSelected).length;
    const langBrowser = this.translateService.getBrowserLang();
    const currentLang = validationLangBrowser
      ? langSelected.value
      : langBrowser;

    if (currentLang === "en") {
      this.i18nMonthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    } else {
      this.i18nMonthsShort = ["Jan","Fev","Mar","Abr","Mai","Jun","Jul","Ago","Set","Out","Nov","Dez"];
    }
  }

  private initializeChart(){
    this.tooltip = {
      shared: false,
      followCursor: true,
      custom: ({series, seriesIndex, dataPointIndex, w}) => {
        return this.customTooltip(series, seriesIndex, dataPointIndex, w);
      }
    };
    this.labels = this.i18nMonthsShort;
    this.grid = {
      strokeDashArray: 7,
      borderColor: "#1C69D4",
    };
    this.legend = {
      show: false
    };
    this.series = [
      {
        name: "Bagunça",
        data: [10, 20, 24.5, 11, 20, 25, 30, 18, 24, 18, 0, 10],
        color: "#24B278",
      },
      {
        name: "Baguncinhas",
        data: [5, 11, 9.5, 21, 16, 12, 20, 17, 9.5, 21, 0, 10],
        color: "#188ECC"
      }
    ];

    this.chart = {
      height: 276,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      type: this.typeChart,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false,
      },
    };
    this.stroke = {
      curve: "straight",
      width: 2
    };
    this.colors = ["#24B278", "#188ECC"];
    this.dataLabels = {
      enabled: false
    };
    this.fill = {
      opacity: [0, 0.1],
      type: ["solid", "gradient"],
      gradient: {
        shade: "light",
        gradientToColors: ["#D9D9D900"],
        inverseColors: false,
        shadeIntensity: 0.3,
        type: "vertical",
        opacityFrom: 0.6,
        opacityTo: 0.2,
        stops: [0, 100]
      }
    };
    this.yAxis = {
      labels: {
        formatter: (value) => {
          return `${value} ${value > 0 ? " mi" : ""}`;
        }
      },
    };
  }

  updateChart(){
    console.log(this.apexChart);
  }

  catchValue(event: any) {
    console.log(event);
  }

  private customTooltip(series: any, seriesIndex: any, dataPointIndex: any, w: any) {
    return `
      <div class="tooltip">
        <p>${w.globals.seriesNames[seriesIndex]}</p>
        <p>${series[seriesIndex][dataPointIndex]}</p>
      </div>
    `;
  }
}
