import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SettingsInterface } from '@interfaces/Settings.interface';
import { SettingsService } from '@services/settings.service';
import { LoadingService } from '@shared/services/dialog/loading.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestGuard implements CanActivate {
  constructor(private loadingService: LoadingService, private settingsService: SettingsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.loadingService.setLoading(true)
      return this.settingsService.getSettings().pipe(
        map((response: SettingsInterface) => {
          if (response) {
            this.loadingService.setLoading(false);
            this.settingsService.saveSettings(response)
            return true;
          } else {
            return false;
          }
        })
      );
  }
  
}
