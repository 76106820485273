import { ConnectionPositionPair } from "@angular/cdk/overlay";

export const POSITION_MAP: { [key: string]: ConnectionPositionPair } = {
   top: new ConnectionPositionPair(
      { originX: "center", originY: "top" },
      { overlayX: "center", overlayY: "bottom" },
   ),
   topLeft: {
      ...new ConnectionPositionPair(
         { originX: "start", originY: "top" },
         { overlayX: "start", overlayY: "bottom" },
      ),
      offsetX: -15,
   },
   topRight: {
      ...new ConnectionPositionPair(
         { originX: "end", originY: "top" },
         { overlayX: "end", overlayY: "bottom" },
      ),
      offsetX: 21,
   },
   right: new ConnectionPositionPair(
      { originX: "end", originY: "center" },
      { overlayX: "start", overlayY: "center" },
   ),
   rightTop: {
      ...new ConnectionPositionPair(
         { originX: "end", originY: "top" },
         { overlayX: "start", overlayY: "top" },
      ),
      offsetY: -24,
   },
   rightBottom: {
      ...new ConnectionPositionPair(
         { originX: "end", originY: "bottom" },
         { overlayX: "start", overlayY: "bottom" },
      ),
      offsetY: 24,
   },
   bottom: new ConnectionPositionPair(
      { originX: "center", originY: "bottom" },
      { overlayX: "center", overlayY: "top" },
   ),
   bottomLeft: {
      ...new ConnectionPositionPair(
         { originX: "start", originY: "bottom" },
         { overlayX: "start", overlayY: "top" },
      ),
      offsetX: -15,
   },
   bottomRight: {
      ...new ConnectionPositionPair(
         { originX: "end", originY: "bottom" },
         { overlayX: "end", overlayY: "top" },
      ),
      offsetX: 0,
   },
   left: new ConnectionPositionPair(
      { originX: "start", originY: "center" },
      { overlayX: "end", overlayY: "center" },
   ),
   leftTop: {
      ...new ConnectionPositionPair(
         { originX: "start", originY: "top" },
         { overlayX: "end", overlayY: "top" },
      ),
      offsetY: -24,
   },
   leftBottom: {
      ...new ConnectionPositionPair(
         { originX: "start", originY: "bottom" },
         { overlayX: "end", overlayY: "bottom" },
      ),
      offsetY: 24,
   },
};
