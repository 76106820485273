import { transition, trigger } from "@angular/animations";
import {
   Component,
   EventEmitter,
   Input,
   Output,
   TemplateRef,
   ViewEncapsulation,
} from "@angular/core";
import * as animations from "@animations";

@Component({
   selector: "options-menu",
   templateUrl: "./options-menu.component.html",
   styleUrls: ["./options-menu.component.scss"],
   encapsulation: ViewEncapsulation.None,
   animations: [
      trigger("openAnimation", [
         transition(":enter", animations.useSlideFadeInAnimation("0.2s", "-20%", "vertical")),
         transition(":leave", animations.useSlideFadeOutAnimation("0.2s", "-20%", "vertical")),
      ]),
   ],
})
export class OptionsMenuComponent {
   @Input() content: TemplateRef<any>;
   @Output() setClose = new EventEmitter<boolean>();
   constructor() {}
}
