import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { DialogRef } from "@shared/services/dialog/dialog-refs.helper";
import { DIALOG_DATA } from "@shared/services/dialog/modal.service";

@Component({
  selector: 'app-disable-confirm',
  templateUrl: './disable-confirm.component.html',
  styleUrls: ['./disable-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisableConfirmComponent implements OnInit {
  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) { }
   public title = "Deseja desabilitar?";
   public message = `O usuário(a) <strong>${this.data?.name}</strong> será desabilitado`;
   public buttonTitle = "Desabilitar";

   ngOnInit(): void {

   }
}
