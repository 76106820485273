import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bluewhale-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input() label: string = '';
  @Input() options: any;
  @Input() form: FormGroup;
  @Input() controlName: string;

  get control() {
    if (!this.form || !this.controlName) {
      return null;
    }
    return this.form.get(this.controlName) as FormControl;
  }
}
