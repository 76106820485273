import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "category-cards",
   templateUrl: "./category-cards.component.html",
   styleUrls: ["./category-cards.component.scss"],
})
export class CategoryCardsComponent {
   @Input() departaments: any;
   @Input() navigation = true;
   @Output() selected = new EventEmitter<string>();
   constructor() {}
}
