import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertStamp'
})
export class ConvertStampPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    return moment(value).format('MM/DD/YYYY HH:mm A')
  }

}
