/* istanbul ignore file */
import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
   selector: "[dropzone]",
})
export class DropzoneDirective {
   @HostBinding("class.fileover") fileOver: boolean;

   @Output() fileDropped = new EventEmitter<any>();

   // Dragover listener
   @HostListener("dragover", ["$event"]) onDragOver(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
   }

   // Dragleave listener
   @HostListener("dragleave", ["$event"]) public onDragLeave(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
   }

   // Drop listener
   @HostListener("drop", ["$event"]) public ondrop(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
      const { files } = evt.dataTransfer;
      const fileEvent = {
         target: {
            files,
         },
      };
      if (files.length > 0) {
         this.fileDropped.emit(fileEvent);
      }
   }
}
