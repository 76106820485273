/* istanbul ignore file */
import { OverlayRef } from "@angular/cdk/overlay";
import { Subject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { DialogConfig } from "./modal.service";

export class DialogRef {
   private afterClosedSubject = new Subject<any>();

   constructor(private overlayRef: OverlayRef, private config: DialogConfig) {
      if (!config.disableClose) {
         this.overlayRef.backdropClick().subscribe(() => {
            this.close();
         });

         this.overlayRef
            .keydownEvents()
            .pipe(filter((event: any) => event.key === "Escape"))
            .subscribe(() => {
               this.close();
            });
      }
   }

   public close(result?: any) {
      this.afterClosedSubject.next(result);
      this.afterClosedSubject.complete();

      this.overlayRef.dispose();
   }

   public afterClosed(): Observable<any> {
      return this.afterClosedSubject.asObservable();
   }
}
