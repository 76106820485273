<section>
   <div class="default-header">
      <h1 [title]="title">{{ title }}</h1>
      <button class="default-header__close" (click)="close()" id="closebutton">
         <span aria-label="Fechar" class="icon-cancel" title="Fechar"></span>
      </button>
   </div>

   <ng-content></ng-content>
</section>
