import { HttpClient, HttpResponse } from "@angular/common/http";
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";

@Pipe({
   name: "httpSafeImage",
   pure: false,
})
export class HttpSafeImagePipe implements PipeTransform, OnDestroy {
   private subscriptions: Subscription[] = [];
   private transformValue = new BehaviorSubject<string>("");

   private latestValue!: string | SafeUrl;

   constructor(
      private httpClient: HttpClient,
      private domSanitizer: DomSanitizer,
      private cdr: ChangeDetectorRef,
   ) {
      this.setUpSubscription();
   }

   transform(imagePath: string): string | SafeUrl {
      this.transformValue.next(imagePath);
      return this.latestValue;
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
   }

   private setUpSubscription(): void {
      this.subscriptions.push(
         this.transformValue
            .asObservable()
            .pipe(
               filter((v): v is string => !!v),
               distinctUntilChanged(),
               switchMap((imagePath: string) =>
                  this.httpClient
                     .get(imagePath, { observe: "response", responseType: "blob" })
                     .pipe(
                        map((response: HttpResponse<Blob>) =>
                           URL.createObjectURL(response.body as any),
                        ),
                        map((unsafeBlobUrl: string) =>
                           this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl),
                        ),
                        filter(blobUrl => blobUrl !== this.latestValue),
                     ),
               ),
               tap((imagePath: string | SafeUrl) => {
                  this.latestValue = imagePath;
                  this.cdr.markForCheck();
               }),
            )
            .subscribe(),
      );
   }
}
