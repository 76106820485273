<section class="bluewhale-table" [@slideAnimation]="tableType !== 'stats' ? 'notActive' : 'active'">
   <p-table
      #bluewhaleTable
      [value]="customers"
      [paginator]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50]"
      [globalFilterFields]="filterName"
      [rows]="5"
      responsiveLayout="stack"
      dataKey="id"
      [breakpoint]="'768px'"
   >
      <!-- // Usando input de pesquisa com api -->
      <ng-template pTemplate="caption" *ngIf="hasSearchInput; else defaultInput">
         <div class="flex">
            <span class="p-input-icon-left ml-auto search-header">
               <span class="icon-search icon"></span>
               <input
                  pInputText
                  type="text"
                  (input)="outValue($event.target)"
                  placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
               />
            </span>
         </div>
      </ng-template>
      <!-- // Usando input de pesquisa do front -->
      <ng-template #defaultInput>
         <ng-template pTemplate="caption">
            <div class="flex">
               <span class="p-input-icon-left ml-auto search-header">
                  <span class="icon-search icon"></span>
                  <input
                     pInputText
                     type="text"
                     (input)="bluewhaleTable.filterGlobal($any($event.target)?.value, 'contains')"
                     placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
                  />
               </span>
            </div>
         </ng-template>
      </ng-template>

      <ng-template pTemplate="header">
         <tr>
            <th
               [ngStyle]="{ width: column === 'Foto' || 'Id' ? '5%' : '25%' }"
               *ngFor="let column of columns"
               style="width: 25%"
            >
               {{ column }}
            </th>
         </tr>
      </ng-template>
      <!-- ---- Users --- -->
      <ng-template *ngIf="tableType === 'users'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td *ngIf="customer.photo; else defaultPhoto">
               <img [src]="customer.photo" style="border-radius: 50%" width="40px;" />
            </td>
            <ng-template #defaultPhoto>
               <td style="width: 5%">
                  <img src="assets/imgs/avatar.svg" width="40px" style="border-radius: 50%" />
               </td>
            </ng-template>
            <td>{{ customer.name }}</td>
            <td>
               <a [href]="'mailto:' + customer.email">{{ customer.email }}</a>
            </td>
            <td>
               <a [href]="'tel:+' + customer.phone">{{ customer.phone }}</a>
            </td>
            <td>{{ customer.birthDate ? customer.birthDate : 'N/A' }}</td>
            <td>
               <div class="row-inline">
                  <span
                     *ngIf="customer.phone && customer.email"
                     [ngClass]="{
                        'color-primary-button--disabled': !customer.phone && !customer.email
                     }"
                     class="icon-key color-primary-button"
                     (click)="eventItemClicked(customer, 'generate')"
                     [tooltip]="
                        customer.phone && customer.email
                           ? 'Gerar senha OTP'
                           : 'Cadastrar Email e Senha'
                     "
                  ></span>
                  <img
                     role="button"
                     src="assets/imgs/blocked-user.svg"
                     (click)="eventItemClicked(customer, 'disable')"
                     tooltip="Desabilitar usuário"
                     class="edit-button"
                  />
               </div>
            </td>
         </tr>
      </ng-template>
      <!-- ---- videos --- -->
      <ng-template *ngIf="tableType === 'videos'" pTemplate="body" let-customer>
         <tr>
            <td>
               <img class="video-thumb" [src]="customer.thumbnail" alt="" />
            </td>
            <td>{{ customer.id }}</td>
            <td>{{ customer.user.nickname }}</td>
            <td>{{ customer.title }}</td>
            <td>{{ customer.category.displayName }}</td>
            <td>
               <mat-checkbox [(ngModel)]="customer.isPublic" [disabled]="true"></mat-checkbox>
            </td>
            <td>
               <div class="row-inline">
                  <span
                     role="button"
                     (click)="eventItemClicked(customer, 'edit')"
                     tooltip="Editar usuário"
                     class="icon-pencil color-primary-button"
                  ></span>
               </div>
            </td>
         </tr>
      </ng-template>
      <!-- ---- plans ---- -->
      <ng-template *ngIf="tableType === 'plans'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td>{{ customer.description }}</td>
            <td>{{ customer.value | currency : 'BRL' }}</td>
            <td>{{ customer.createdAt | date : 'dd/MM/yyyy' }}</td>
            <td>{{ customer.updatedAt | date : 'dd/MM/yyyy' }}</td>
         </tr>
      </ng-template>
      <ng-template *ngIf="tableType === 'payments'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td>{{ customer.amount | currency : 'BRL' }}</td>
            <td>
               {{ customer.interest | currency : 'BRL' }}
            </td>
            <td>{{ customer.method }}</td>
            <td>
               <p [class]="customer.status">{{ customer.status | transformStatusPayment }}</p>
            </td>
            <td>{{ customer.user.nickname }}</td>
            <td>{{ customer.createdAt | date : 'dd/MM/yyyy' }}</td>
            <td>{{ customer.updatedAt | date : 'dd/MM/yyyy' }}</td>
         </tr>
      </ng-template>
   </p-table>
</section>
