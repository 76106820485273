import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { DialogRef } from "@shared/services/dialog/dialog-refs.helper";
import { DIALOG_DATA } from "@shared/services/dialog/modal.service";

@Component({
   selector: "app-delete-confirm",
   templateUrl: "./delete-confirm.component.html",
   styleUrls: ["./delete-confirm.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class DeleteConfirmComponent implements OnInit {
   constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) { }
   public title = "Deseja realizar a exclusão?";
   public message = "Esta ação é irreversível, realmente deseja excluir este registro?";
   public buttonTitle = "Excluir";

   ngOnInit(): void {
      if (this.data?.action === "suspend") {
         this.title = "Deseja suspender o anúncio?";
         this.message = `O anúncio <strong>${this.data.title.toUpperCase()}</strong> será suspenso imediatamente.`;
         this.buttonTitle = "Suspender";
      }
   }
}
