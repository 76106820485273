<div class="toast">
   <p class="toast__title">{{ content.title }}</p>
   <button class="toast__close" (click)="onClose()">
      <span class="icon-cancel"></span>
   </button>
   <div class="toast__mgs">
      <!-- <span [class]="'material-icons icon ' + content?.type">cancel</span> -->
      <span [class]="'icon-' + content.icon + ' iconconf ' + content.type"></span>
      <p>{{ content.text }}</p>
   </div>
</div>
