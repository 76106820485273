import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesTypes } from '@enums/files-type.enum';
import { UploadService } from '@services/upload.service';
import { ImageTransform } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { ToastComponent } from '../toast/toast.component';
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper';
import { ELocalStorage } from '@enums/localstorage.enum';

@Component({
  selector: 'app-dialog-profile-img',
  templateUrl: './dialog-profile-img.component.html',
  styleUrls: ['./dialog-profile-img.component.scss'],
})
export class DialogProfileImgComponent implements OnDestroy {
   imageChangedEvent: any = '';
   croppedImage: any = '';
   transform: ImageTransform = {};
   rotation: number = 0
   canvasRotation: number = 0
   translateH = 0;
   translateV = 0;
   zoom: number = 0
   ajustImg: number = 0
   isRotating: number = 0
   imgOnly: string = FilesTypes.img_only
   savingLoading: boolean = false;
   public subscriptions: Subscription[] = [];
   constructor(
      private uploadService: UploadService, 
      private snackBar: MatSnackBar, 
      private dialogRef: DialogRef, 
   ){}

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
   }
   rotateLeft() {
      this.isRotating = 1
      setTimeout(() => {
         this.canvasRotation--;
         this.flipAfterRotate();
      });
   }

   rotateRight() {
      this.isRotating = 2
      setTimeout(() => {
         this.canvasRotation++;
         this.flipAfterRotate();
      });
   }
    flipHorizontal() {
      this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
      };
    }

    flipVertical() {
      this.transform = {
        ...this.transform,
        flipV: !this.transform.flipV
      };
    }

   changeZoom(ev: any) {
      const scaleSize: any = ev.value/10
      const newScale = 1 + parseFloat(scaleSize);
      this.transform = {
         ...this.transform,
         scale: newScale,
       };
   }

   resetImage(){
      this.canvasRotation = 0
      this.zoom = 0
      this.ajustImg = 0
      this.transform = {};
   }
   rotateImage(ev: any){
      this.transform = {
         ...this.transform,
         rotate: ev.value,
       };
   }

   fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
   }
   private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
      };
      this.translateH = 0;
      this.translateV = 0;
   }

   saveImage(){
      this.savingLoading = true
      const formData = new FormData();
      formData.append('profile', this.croppedImage, 'profile_image.png');
      this.subscriptions.push(
         this.uploadService
               .uploadFile(formData, 'image', false)
               .subscribe({
                  next: (res: string[]) => {
                     this.saveImageStorage(res[0])
                     this.dialogRef.close()
                  },
                  error: error => {
                     const data: any = {
                        title: "Error",
                        text: error.error.message,
                        type: "error",
                        icon: "cancel-circle",
                     };
                     this.openToast(data);
                  },
               })
      )
   }

   openToast(data: any) {
      this.snackBar.openFromComponent(ToastComponent, {
         verticalPosition: 'bottom',
         data: data,
         horizontalPosition: 'right',
      })
   }

   saveImageStorage(imgUrl: string){
      window.localStorage.setItem(ELocalStorage.USER_IMAGE, imgUrl);
   }
}
