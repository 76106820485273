import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "alert",
   templateUrl: "./alerts.component.html",
   styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent {
   @Output() off = new EventEmitter<boolean>();
   @Input() text: string;
   @Input() type: "error" | "warn" | "success" | "info";
   @Input() icon: string;
   constructor() {}
}
