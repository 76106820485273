import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@environment";
import { HAS_LOADING } from "@helpers/constants/contexts.helper";
import { AdminResponse } from "@interfaces/admin-response.interface";
import { AuthRequest, AuthResponse } from "@interfaces/auth.interface";
import { RegisterAddress, RegisterResponse, UserResquest } from "@interfaces/register.interface";
import { LoadingService } from "@shared/services/dialog/loading.service";
import { Observable } from "rxjs";
@Injectable({
   providedIn: "root",
})
export class UserService {
   private readonly apiUrl = environment.apiUrl;
   constructor(
      private httpClient: HttpClient, 
      private router: Router,
      private loading: LoadingService
      ) { }

   public setFavorite(route: string, id: number): Observable<{ success: string }> {
      const headers = new HttpHeaders();
      return this.httpClient.get<{ success: string }>(`${this.apiUrl}${route}/favorite/${id}`, {
         headers,
      });
   }

   listSingleUser(id: string): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.get<any>(`${this.apiUrl}users/admin/get/${id}`, {
         headers,
         context: new HttpContext().set(HAS_LOADING, true),
      });
   }

   public deleteUser(id: string | number): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.delete<any>(`${this.apiUrl}users/admin/delete/${id}`, {
         headers,
      });
   }

   public logout(): void {
      this.loading.setLoading(true)
      setTimeout(() => {
         localStorage.clear();
         this.router.navigate(["login"]);
         this.loading.setLoading(false)
      }, 1200);
   }

   public getAuth(data: AuthRequest): Observable<AuthResponse> {
      const headers = new HttpHeaders();
      const params = new HttpParams();
      return this.httpClient.post<AuthResponse>(`${this.apiUrl}auth/client`, data, {
         headers,
         params,
      });
   }
   public getAdmindata(): Observable<AdminResponse> {
      const headers = new HttpHeaders();
      return this.httpClient.get<AdminResponse>(`${this.apiUrl}auth/profile`, {
         headers,
      });
   }

   deleteAddress(id: number | string): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.delete<any>(`${this.apiUrl}users-addresses/admin/delete/${id}`, {
         headers,
      });
   }
   public postResgisterUser(data: UserResquest): Observable<RegisterResponse> {
      const headers = new HttpHeaders();
      const params = new HttpParams();
      return this.httpClient.post<RegisterResponse>(`${this.apiUrl}users/signup`, data, {
         headers,
         params,
      });
   }
   public postResgisterAddress(data: RegisterResponse): Observable<RegisterAddress> {
      const headers = new HttpHeaders();
      const params = new HttpParams();
      return this.httpClient.post<RegisterAddress>(
         `${this.apiUrl}users-addresses/admin/create`,
         data,
         {
            headers,
            params,
         },
      );
   }

   tempPassword(id: string | number): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.get<any>(`${this.apiUrl}users/admin/tmpPasswd/${id}`, {
         headers,
      });
   }

   updateUser(id: string | number, data: any): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.patch<any>(`${this.apiUrl}users/admin/update/${id}`, data, {
         headers,
      });
   }
   updateContactUser(id: string | number, data: any): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.patch<any>(`${this.apiUrl}users-contacts/admin/update/${id}`, data, {
         headers,
      });
   }

   updateProfilePhoto(img: string): Observable<any> {
      const headers = new HttpHeaders();
      return this.httpClient.get<any>(`${this.apiUrl}users/profile/photo?image=${img}`, {
         headers,
      });
   }
}
