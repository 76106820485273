<base-dialog title="{{ title }}">
   <div class="delete-container">
      <img
         src="https://upload.wikimedia.org/wikipedia/commons/7/74/Feedbin-Icon-error.svg"
         alt=""
      />
      <p class="text" [innerHTML]="message"></p>
      <button class="button" (click)="dialogRef.close(true)">{{ buttonTitle }}</button>
   </div>
</base-dialog>
