/* istanbul ignore file */
import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   Output,
   QueryList,
   ViewChild,
   ViewChildren,
} from '@angular/core'
import {
   ControlContainer,
   ControlValueAccessor,
   FormControl,
   FormControlDirective,
   NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { Observable, Subscription } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

export interface User {
   name: string
}
@Component({
   selector: 'inputs',
   templateUrl: './inputs.component.html',
   styleUrls: ['./inputs.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: InputsComponent,
      },
   ],
})
export class InputsComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
   @ViewChild(FormControlDirective, { static: false }) formControlDirective: FormControlDirective
   @ViewChildren('otp') inputOtp: QueryList<InputsComponent>

   @Input() formControl: FormControl
   @Input() NameformControl: string = ''
   @Input() placeholder: string = ''
   @Input() label: string = ''
   @Input() input_labels: { label: string; checked?: boolean; value: any }[]
   @Input() i_mask: string = ''
   @Input() thousandSeparator: string = ''
   @Input() prefix: string = ''
   @Input() icon: string = ''
   @Input() type: string
   @Input() hint: string
   @Input() value: any
   @Input() margin: string
   @Input() readonly: boolean
   @Input() maxlength: number = 0
   @Input() loading: boolean = false
   @Input() submitted: boolean = false
   @Input() remove_mask: boolean = true
   @Input() input_type: any
   @Input() setErros: any
   @Input() rows: any
   @Input() checked: boolean | string
   @Input() data: any
   public openSelect: boolean

   @Output() focusOut = new EventEmitter<any>()
   @Output() toggleEvent = new EventEmitter<any>()
   @Output() selectInstallment = new EventEmitter<any>()
   public passwordStrength: number = 0
   public subscriptions: Subscription[] = []
   public slideVal: number = 0
   public valSum: number = 0
   config = {
      length: 6,
      allowNumbersOnly: true,
      containerClass: 'otp-inputs__container',
      inputClass: 'otp-inputs__container-item',
   }
   public errorMessageResource: any = {
      required: 'Campo obrigatório',
      minlength: 'A senha deve possuir ao menos 6 dígitos',
      noUpperCase: 'A senha deve ter ao menos uma letra maiúscula',
      noLowercase: 'A senha deve ter ao menos uma letra minúscula',
      noNumber: 'A senha deve ter ao menos um número',
      noSpecial: 'A senha deve ter ao menos um caractere especial',
   }
   public options = {
      align: 'right',
      prefix: 'R$ ',
      allowNegative: false,
      allowZero: true,
      decimal: ',',
      precision: 2,
      suffix: '',
      thousands: '.',
   }

   public toggleEyeInput: boolean = true

   myControl = new FormControl('')
   optionss: any[] = [{ name: 'Mary' }, { name: 'Shelley' }, { name: 'Igor' }]
   filteredOptions: Observable<any[]>

   constructor(private controlContainer: ControlContainer) {}

   get control() {
      return this.formControl || this.controlContainer.control?.get(this.NameformControl)
   }

   ngOnChanges() {
      this.autoCompleteFilter()
      if (this.input_type === 'slider') {
         this.slideVal = this.value
         this.valSum = this.sumVal(this.slideVal, 0, 500)
      }
   }

   ngOnInit() {
      if (this.input_type === 'password-valid') {
         this.subscriptions.push(
            this.control.valueChanges.subscribe(() => {
               const value: string = this.control?.value

               let progress: number = 0
               progress += +!!value.match(/[0-9]/) && +!!value.match(/[^A-Za-z 0-9]/g)
               progress += +!!value.match(/[A-Z]/) && +!!value.match(/[a-z]/)
               progress += +(value.length >= 8)
               this.passwordStrength = progress || 1
            }),
         )
      }
   }

   autoCompleteFilter() {
      if (this.input_type === 'auto-complete' && this.data?.length) {
         this.filteredOptions = this.control.valueChanges.pipe(
            startWith(''),
            map((value: any) => (typeof value === 'string' ? value : value.COMPE)),
            map((value: any) => (value ? this._filter(value) : this.data?.slice())),
         )
      }
   }

   displayFn(value: any): string {
      return value ? `${value?.COMPE} - ${value?.ShortName}` : ''
   }

   private _filter(value: string = ''): any {
      const filterValue = this._normalizeValue(value)
      return this.data.filter(
         (option: any) =>
            option?.ShortName.toLowerCase().includes(filterValue) ||
            option?.LongName.toLowerCase().includes(filterValue) ||
            option?.COMPE.toLowerCase().includes(filterValue),
      )
   }

   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe())
   }

   registerOnTouched(fn: any): void {
      this.formControlDirective?.valueAccessor?.registerOnTouched(fn)
   }

   registerOnChange(fn: any): void {
      this.formControlDirective?.valueAccessor?.registerOnChange(fn)
   }

   writeValue(obj: any): void {
      this.formControlDirective?.valueAccessor?.writeValue(obj)
   }

   setDisabledState(isDisabled: boolean): void {
      this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled)
   }

   attrConf(input: HTMLElement) {
      if (this.maxlength <= 0) {
         input.removeAttribute('maxlength')
      }
   }

   toggleEye(ev: HTMLInputElement) {
      this.toggleEyeInput = !this.toggleEyeInput
      if (ev.type === 'password') {
         ev.type = 'text'
      } else {
         ev.type = 'password'
      }
   }

   setVal(ev: any) {
      this.slideVal = ev.target.value
      const value = this.sumVal(this.slideVal, ev.target.min, ev.target.max)
      ev.target.style.background =
         'linear-gradient(to right, #1251ff 0%, #1251ff ' +
         value +
         '%, #fff ' +
         value +
         '%, white 100%)'
   }

   sumVal(slide: number, min: number, max: number) {
      return ((slide - min) / (max - min)) * 100
   }

   handleKeyup(e: any, index: number) {
      const input = e.target
      let value = input.value
      input.value = ''
      input.value = value ? value[0] : ''
      this.keysHandler(e, index, this.inputOtp.toArray())
      if (value.length > 0 && index < this.inputOtp.length - 1) {
         input.nextElementSibling.focus()
      }
      if (index === this.inputOtp.length - 1) {
         let valueOtp = ''
         this.inputOtp.toArray().forEach((inpt: any) => {
            valueOtp += inpt.nativeElement.value
         })

         this.control.setValue(valueOtp)
      }
   }

   keysHandler(e: any, index: number, inputs: any) {
      if (e.key == 'ArrowLeft' && index > 0) {
         e.preventDefault()
         inputs[index - 1].nativeElement.focus()
      }
      if (e.key == 'ArrowRight' && index + 1 < inputs.length) {
         e.preventDefault()
         inputs[index + 1].nativeElement.focus()
      }
      if (e.key === 'Backspace' && index > 0) {
         e.target.previousElementSibling.focus()
      }
   }

   handlerPasteOtp(e: any) {
      const data = e.clipboardData.getData('text')
      const inputs = this.inputOtp.toArray()
      const value = data.split('')
      if (value.length === this.inputOtp.length) {
         inputs.forEach((inpt: any, i: number) => {
            inpt.nativeElement.value = value[i]

            if (i + 1 === this.inputOtp.length) {
               inpt.nativeElement.focus()
            }
         })
      }
   }

   _normalizeValue(value: string = ''): string {
      return value.toLowerCase().replace(/\s/g, '')
   }
}
