import { Pipe, PipeTransform } from '@angular/core'
import { PaymentStatus } from '@enums/paymentStatus.enum'

@Pipe({
   name: 'transformStatusPayment',
})
export class TransformStatusPaymentPipe implements PipeTransform {
   transform(value: string): string {
      let statusName: string = ''

      switch (value) {
         case PaymentStatus.WAITING:
            statusName = 'Aguardando'
            break

         case PaymentStatus.SUCCESS:
            statusName = 'Sucesso'
            break

         case PaymentStatus.FAILED:
            statusName = 'Falha'
            break

         default:
            PaymentStatus.FAILED
            statusName = 'Falha'
            break
      }
      return statusName
   }
}
