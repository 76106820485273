import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "search-input",
   templateUrl: "./search-input.component.html",
   styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
   @Input() placeholder = "Faça sua pesquisa";
   @Output() changeSearch = new EventEmitter<any>();

   public searchFaq!: any;
   public faqs!: any;

   constructor() {}
}
