import { trigger, state, style, transition, animate } from '@angular/animations'
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { ModalService } from '@shared/services/dialog/modal.service'
import { Subscription } from 'rxjs'

@Component({
   selector: 'bluewhale-table',
   templateUrl: './bluewhale-table.component.html',
   styleUrls: ['./bluewhale-table.component.scss'],
   animations: [
      trigger('slideAnimation', [
         state('notActive', style({ transform: 'translateX(0)', opacity: 1 })),
         state('active', style({})),
         transition('* => notActive', [
            style({ transform: 'translateX(-100%)', opacity: 0 }),
            animate('500ms ease-out'),
         ]),
         transition('notActive => active', animate('500ms ease-in')),
         transition(':enter', animate('500ms ease-out')),
         transition(':leave', animate('500ms ease-in')),
      ]),
   ],
})
export class BluewhaleTableComponent implements OnDestroy {
   public subscriptions: Subscription[] = []
   constructor(public router: Router, private _modalService: ModalService) {}
   @Input() customers: any
   @Input() columns: string[]
   @Input() subColumns: string[]
   @Input() loadingTable: boolean
   @Input() tableType: string
   @Input() hasGeolocation: boolean = false
   @Input() filterName!: any
   @Input() hasSearchInput: boolean

   @Output() outPutEventCustomer = new EventEmitter<any>()
   @Output() outSearch = new EventEmitter<any>()

   ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe)
   }

   eventItemClicked(customerItems: any, event?: any) {
      this.outPutEventCustomer.emit({ customerItems, event })
   }

   convertTimestampToTime(time: string, data?: any) {
      let formattedTime = moment(time, 'HH:mm:ss').format('HH:mm A')
      return formattedTime
   }

   outValue(ev: any) {
      this.outSearch.emit(ev)
   }
}
