import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup } from '@angular/forms';
import { UploadService } from '@services/upload.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'upload-image',
  templateUrl: './upload.component.html',
  styleUrls: ["./upload.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ]
})
export class UploadComponent implements ControlValueAccessor {
  @Input() form: FormGroup;
  @Input() formControlName: string;
  imagePreview: any;
  onChange: Function;
  onTouched: Function;
  public isLoading = false;
  
  constructor(
    private uploadService: UploadService,
  ) {}

  writeValue(value: any): void {
    if (value) {
      this.imagePreview = value;
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  onFileChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    let file = element.files ? element.files[0] : null;
    if (file && file.type === 'image/png') {
      this.uploadAndPreviewImage(file);
    } else {
      this.form.controls[this.formControlName].setValue(null);
      this.imagePreview = '';
    }
  }

  clearImage(): void {
    this.imagePreview = '';
    this.form.controls[this.formControlName].setValue(null);
    this.onChange(null);
  }

  uploadAndPreviewImage(file: File) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', file, 'thumbnail.png');

    this.uploadService.uploadFile(formData, 'image')
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe({
      next: (res: any) => {
        this.imagePreview = res.body;
        this.onChange(this.imagePreview);
        this.form.controls[this.formControlName].setValue(this.imagePreview);
      },
      error: (err) => {
        console.error('Erro ao enviar imagem:', err);
      }
    });
  }

}
