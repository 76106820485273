import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environment'
import { ELocalStorage } from '@enums/localstorage.enum'
import { Observable } from 'rxjs'
import { SessionStorageEnum } from '@enums/sessionStorage.num'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   private token_header_key = 'Authorization'
   private token_client_key = 'Client-Token'
   readonly api_url: string = environment.apiUrl

   constructor() {}

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let token
      let tokenClient: string = JSON.parse(
         sessionStorage.getItem(SessionStorageEnum.SETTINGS) || '{}',
      )?.backend?.clientToken

      token = localStorage.getItem(ELocalStorage.USER_TOKEN)

      if (token) {
         request = request.clone({
            headers: request.headers.set(
               this.token_header_key,
               'Bearer ' + JSON.parse(token).token,
            ),
         })
      }

      if (tokenClient) {
         request = request.clone({
            headers: request.headers.set(this.token_client_key, tokenClient),
         })
      }

      return next.handle(request)
   }
}
