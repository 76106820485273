<base-dialog [title]="data.title">
   <form [formGroup]="formInput" (ngSubmit)="sendReason()">
      <inputs
         input_type="textarea"
         [placeholder]="data.placeholder"
         NameformControl="reason"
         [rows]="5"
         [submitted]="submitted"
      ></inputs>

      <button class="button" type="submit">Enviar</button>
   </form>
</base-dialog>
