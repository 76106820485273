import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

interface ContentToast {
   type: "error" | "success" | "warn"
   title: string
   text: string
   icon: "cancel-circle" | "ok-circle" | "warn"
}
@Component({
   selector: "toast",
   templateUrl: "./toast.component.html",
   styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {
   public content: ContentToast = {
      type: "error",
      title: "Ocorreu um erro",
      text: "Verifique os campos e tente novamente",
      icon: "cancel-circle",
   };
   constructor(
      public snackBarRef: MatSnackBarRef<ToastComponent>,
      @Inject(MAT_SNACK_BAR_DATA) data: any,
   ) {
      if (data) {
         Object.assign(this.content, data);
      }
   }

   onClose(): void {
      this.snackBarRef.dismiss();
   }
}
