import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmitContentData } from '@interfaces/content.interface';

@Component({
  selector: 'content-data',
  templateUrl: './content-data.component.html',
  styleUrls: ['./content-data.component.scss']
})
export class ContentDataComponent implements OnInit {
   @Input() settingsDataContent: any
   @Output() emitData = new EventEmitter<EmitContentData>()

   customFormGroup: FormGroup;
   editMode: { [key: string]: boolean } = {}

   ngOnInit(): void {
      this.buildForms()
   }

   buildForms() {
      const formControls = this.settingsDataContent.rows.reduce((controls: any, row: any) => {
        if (row.value === "true" || row.value === "1") {
         row.value = true
        } else if (row.value === "false" || row.value === "0") {
         row.value = false
        }
        controls[row.key] = new FormControl(row.value, this.getValidators(row.type));
        this.editMode[row.key] = false;
        return controls;
      }, {});

      this.customFormGroup = new FormGroup(formControls);
   }

   handleToggleEvent(ev: any) {}

   getValidators(type: string) {
      switch (type) {
         case 'input':
            return [Validators.required];
         case 'toggle':
            return [Validators.required];
         case 'textarea':
            return [Validators.required];
         default:
            return [];
      }
   }

   editRow(nameRow: string) {
      this.editMode[nameRow] = true;
   }

   cancel(nameRow: string) {
      this.editMode[nameRow] = false;
   }

   saveRow(nameRow: string) {
      this.editMode[nameRow] = false;
      const updatedValue = this.customFormGroup.get(nameRow)?.value;
      const row = this.settingsDataContent?.rows?.find((r: any) => r.key === nameRow);
      const updatedControl = { key: nameRow, value: updatedValue, type: row.type, parentKey: this.settingsDataContent.key };
      this.emitData.emit(updatedControl);
   }
}
