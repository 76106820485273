import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageEnum } from '@enums/sessionStorage.num';
import { environment } from '@environment';
import { SettingsGeneralInterface, SettingsIntegrationInterface, SettingsInterface, SettingsPatchInterface, SettingsWebInterface } from '@interfaces/Settings.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly apiUrl = environment.apiUrl;

  constructor(
     private httpClient: HttpClient,
  ) { }

  getSettings(): Observable<SettingsInterface> {
    return this.httpClient.get<SettingsInterface>(`${this.apiUrl}settings/web/get`)
  }

  updateSettings(patchData: SettingsPatchInterface): Observable<SettingsPatchInterface> {
    return this.httpClient.patch<SettingsPatchInterface>(`${this.apiUrl}settings/client/update`, patchData)
  }

  getSettingsToWeb(): Observable<SettingsWebInterface[]> {
    return this.httpClient.get<SettingsWebInterface[]>(`${this.apiUrl}settings/client/get/web`)
  }


  getSettingsGeneral(): Observable<SettingsGeneralInterface[]> {
    return this.httpClient.get<SettingsGeneralInterface[]>(`${this.apiUrl}settings/client/get/general`)
  }

  getSettingsIntegration(): Observable<SettingsIntegrationInterface[]> {
    return this.httpClient.get<SettingsIntegrationInterface[]>(`${this.apiUrl}settings/client/get/integration`)
  }

  saveSettings(settings: SettingsInterface): void {
    sessionStorage.setItem(SessionStorageEnum.SETTINGS, JSON.stringify(settings));
  }
}
