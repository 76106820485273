import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "borderColor",
})
export class BorderColorPipe implements PipeTransform {
   transform(value: any): string {
      let color = "#4955e0";

      switch (value?.type) {
         case "retirada":
            color = "#000000";
            break;
         case "venda":
            color = "#f4792c";
            break;

         default:
            color = "#f4792c";
            break;
      }

      return color;
   }
}
