import { Component, Input } from "@angular/core";
import { DialogRef } from "@shared/services/dialog/dialog-refs.helper";

@Component({
   selector: "base-dialog",
   templateUrl: "./base-dialog.component.html",
   styleUrls: ["./base-dialog.component.scss"],
})
export class BaseDialogComponent {
   @Input() title: string;
   constructor(private dialogRef: DialogRef) {}

   close() {
      this.dialogRef.close();
   }
}
