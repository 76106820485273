import { Component } from '@angular/core';

@Component({
  selector: 'bluewhale-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

  public dataTags = [
    {
      value: 150,
      label: 'Total de usuários',
      color: '#17A2B8',
      icon: 'icon-chart-bar'
    },
    {
      value: 80,
      label: 'Assinaturas ativas',
      color: '#28a745',
      icon: 'icon-star-1'
    },
    {
      value: 70,
      label: 'Assinaturas aguardando renovação',
      color: '#ffc107',
      icon: 'icon-warn'
    },
  ]
}
