<div class="grid">
   <section class="content-data">
      <div class="content-data__container">
         <div class="content-data__container-title">
            <h3 class="content-data__container-title-text">
               Página: {{ settingsDataContent.key }}
            </h3>
         </div>
         <form class="content-data__container-wrapper">
            <div
               class="content-data__container-wrapper-controls"
               *ngFor="let info of settingsDataContent.rows"
            >
               <!-- Mostrar campo de entrada apenas se estiver em modo de edição -->
               <div
                  [formGroup]="customFormGroup"
                  class="content-data__container-wrapper-controls-row"
                  *ngIf="editMode[info.key]"
               >
                  <inputs
                     [NameformControl]="info.key"
                     [type]="info.type"
                     [label]="info.key"
                     [input_type]="info.type"
                     [checked]="info.value"
                     [value]="info.value"
                     [data]="info.option"
                     (toggleEvent)="handleToggleEvent($event.target.value)"
                  />
                  <button
                     type="button"
                     class="save-button"
                     [disabled]="
                        customFormGroup.get(info.key)?.invalid ||
                        customFormGroup.get(info.key)?.pristine
                     "
                     (click)="saveRow(info.key)"
                  >
                     Salvar
                  </button>
                  <button class="cancel-button" (click)="cancel(info.key)">Cancelar</button>
               </div>

               <!-- Mostrar valor apenas se não estiver em modo de edição -->
               <ng-container *ngIf="!editMode[info.key]">
                  <div class="content-data__container-wrapper-controls-data">
                     <div class="content-data__container-wrapper-controls-data-rows">
                        <p class="title">{{ info.key }}</p>
                        <p
                           class="value"
                           *ngIf="info.type !== 'toggle'; else toggleValue"
                           [tooltip]="info.value"
                        >
                           {{ info.value }}
                        </p>
                        <ng-template #toggleValue>
                           <p class="value">{{ info.value === true ? 'Ativado' : 'Desativado' }}</p>
                        </ng-template>
                        <span class="icon-pencil button-edit" (click)="editRow(info.key)"></span>
                     </div>
                  </div>
               </ng-container>
            </div>
         </form>
      </div>
   </section>
</div>
