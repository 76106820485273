import { Location } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
   selector: "sidebar-structure",
   templateUrl: "./sidebar-structure.component.html",
   styleUrls: ["./sidebar-structure.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class SidebarStructureComponent {
   @Input() back: string;
   @Input() title: string;
   public userData: any;
   public pageName: string;

   constructor(public location: Location) {}
}
