import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper';
import { DIALOG_DATA, ModalService } from '@shared/services/dialog/modal.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from '@services/categories.service';
import { CategoryInterface } from '@interfaces/category.interface';
import '@mux/mux-uploader';
import { VideosService } from '@services/videos.service';
import { finalize } from 'rxjs/operators';
import { ToastComponent } from '../toast/toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogProfileImgComponent } from '../dialog-profile-img/dialog-profile-img.component';
import { ELocalStorage } from '@enums/localstorage.enum';

interface OptionsRadio {
  name: string
  value: boolean
}
@Component({
  selector: 'app-modal-add-video',
  templateUrl: './modal-add-video.component.html',
  styleUrls: ['./modal-add-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ModalAddVideoComponent implements OnInit {
  @ViewChild('mux') muxUploader: any;
  public isLoading: boolean = false;
  public isLoadingVideo: boolean = false;
  public submitted: boolean = false;
  public subscriptions: Subscription[] = [];
  public videoFormGroup: FormGroup;
  public dataOptionsCategories: Partial<CategoryInterface>;
  public optionsRadio: OptionsRadio[] = [
    {
      name: 'Sim',
      value: true
    },
    {
      name: 'Não',
      value: false
    }
  ];
  public imagePreview: string | null = '';

  constructor(
    private getAllCategories: CategoriesService,
    private videoService: VideosService,
    public dialogRef: DialogRef,
    private dialog: ModalService,
    private snackBar: MatSnackBar,
    @Inject(DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.buildForm()
    this.displayCategories()
  }

  displayCategories() {
    this.subscriptions.push(
      this.getAllCategories.getCategoriesVideos().subscribe({
        next: (res: Partial<CategoryInterface>) => {
          this.dataOptionsCategories = res
        }
      })
    )
  }

  handleUploadSuccess(event: any) {
    if (event.type === "success") {
      this.videoFormGroup.patchValue({
        uploadVideo: true
      })
    }
  }

  clearImage(): void {
    this.imagePreview = '';
    this.videoFormGroup.get('thumbnail')?.setValue(null);
  }

  sendVideo() {
    this.isLoadingVideo = true
    const payload = {
      title: this.videoFormGroup.get('title')?.value,
      description: this.videoFormGroup.get('description')?.value,
      category: this.videoFormGroup.get('category')?.value,
      isPublic: this.videoFormGroup.get('isPublic')?.value,
      uploadId: this.data?.id,
      thumbnail: window.localStorage.getItem(ELocalStorage.USER_IMAGE),
    }

    this.subscriptions.push(
      this.videoService.createVideo(payload)
      .pipe(finalize(() => this.isLoadingVideo = false))
      .subscribe({
        next: (_) => {
          const data: any = {
            title: 'Sucesso',
            text: 'Vídeo criado com sucesso',
            type: 'success',
            icon: 'ok-circle',
          }
         this.openToast(data)
         this.dialogRef.close()
         window.localStorage.removeItem(ELocalStorage.USER_IMAGE)
        }
      })
    )
  }

  openCropper(){
    this.dialog
    .open(DialogProfileImgComponent, { panelClass: "cropper" })
    .afterClosed()
    .subscribe((_) => {
      this.imagePreview = window.localStorage.getItem(ELocalStorage.USER_IMAGE)
    })
  }

  private buildForm() {
    this.videoFormGroup = new FormGroup({
       title: new FormControl("", Validators.required),
       description: new FormControl("", Validators.required),
       category: new FormControl("", Validators.required),
       isPublic: new FormControl(false, Validators.required),
       thumbnail: new FormControl(false, Validators.required),
       uploadVideo: new FormControl('', Validators.required),
    });
  }

  openToast(data: any) {
    this.snackBar.openFromComponent(ToastComponent, {
       verticalPosition: 'top',
       data: data,
       horizontalPosition: 'right',
    })
 }
}
