import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent {
  public Editor = ClassicEditor;
  public charactersTextarea: string = '';

  @Input() form: FormGroup;
  @Input() inputControlName: string;
  @Input() label: string;
  @Input() limitCharacters: number;

  get inputControl() {
    return this.form.get(this.inputControlName) as FormControl;
  }

  public config: any = {
    placeholder: 'Sua descrição...',
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'imageUploadUI',
        'blockQuote',
        '|',
        'heading',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'highlight',
      ],
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        '|',
        'imageStyle:full',
        'imageStyle:side',
      ],
    },
  };

  onReady(editor: any) {}
}
