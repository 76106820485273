<div class="cards">
   <div class="cards__user">
      <ng-container *ngIf="content?.product_img || content?.user?.profilePhoto; else placeHolder">
         <img class="cards__user-image" [src]="content | urlChanges: type" alt="Foto" />
      </ng-container>
      <ng-template #placeHolder>
         <div *ngIf="type === 'shipping'">
            <span class="icon-bag icon"></span>
         </div>
         <div *ngIf="type !== 'shipping'">
            <span class="icon-person icon"></span>
         </div>
      </ng-template>
      <span class="cards__user-name">
         <ng-content select="[info]"></ng-content>
      </span>
   </div>

   <div class="cards__action">
      <button
         (click)="answer.emit('approve')"
         class="icon-ok"
         [disabled]="content?.processed || loading > 0 || content?.status === 'approved'"
      ></button>
      <button
         *ngIf="hasReject"
         (click)="answer.emit('disapprove')"
         class="icon-cancel"
         [disabled]="content?.processed || loading > 0 || content?.status === 'approved'"
      ></button>
      <div class="open">
         <ng-content select="[button]"></ng-content>
      </div>
   </div>

   <div class="cards__footer">
      <p>
         Requisitado em {{ content?.createdAt | date: 'dd/MM' }} ás
         {{ content?.createdAt | date: 'HH' }}h{{ content?.createdAt | date: 'mm' }}
      </p>
   </div>
</div>
