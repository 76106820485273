/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "status",
})
export class StatusPipe implements PipeTransform {
   transform(value: string, type = "product"): string {
      let realVelue: string;

      if (type === "transaction") {
         switch (value) {
            case "success":
               realVelue = "Aprovado";
               break;
            case "awaiting":
               realVelue = "Aguardando";
               break;
            case "rejected":
               realVelue = "Rejeitado";
               break;

            default:
               "unknown";
               realVelue = "Status desconhecido";
               break;
         }
      } else if (type === "order") {
         switch (value) {
            case "awaitingPayment":
               realVelue = "Aguardando pagamento";
               break;
            case "awaitingShipping":
               realVelue = "Aguardando envio";
               break;
            case "completed":
               realVelue = "Finalizada";
               break;
            case "cancelled":
               realVelue = "Cancelada";
               break;
            case "expired":
               realVelue = "Expirada";
               break;
            default:
               "unknown";
               realVelue = "Status desconhecido";
               break;
         }
      } else {
         switch (value) {
            case "active":
               realVelue = "ativo";
               break;
            case "canceled":
               realVelue = "cancelado";
               break;
            case "paused":
               realVelue = "pausado";
               break;
            case "pendent":
               realVelue = "pendente";
               break;
            case "suspended":
               realVelue = "encerrado";
               break;
            case "finished":
               realVelue = "finalizado";
               break;
            case "unknown":
               realVelue = "Status desconhecido";
               break;

            default:
               "awaitingApproval";
               realVelue = "aguardando";
               break;
         }
      }
      return realVelue;
   }
}
