import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, SimpleChanges, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SelectOptionModel } from '@interfaces/select-interface';

interface SelectedValueInterface {
  id: string | number;
  name: string;
  displayName: string;
}

@Component({
  selector: 'bluewhale-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {
  public arrowToggle: boolean = false

  public showInformer: boolean = true;

  @Input() label: string = 'Selecione uma opção';

  @Input() type: 'default' |  'single' = 'default';

  @Input() form: FormGroup;

  @Input() controlName: string;

  @Input() dataOptions: SelectOptionModel[] | any = [
    {
      id: 1,
      name: 'Teste 1'
    },
    {
      id: 2,
      name: 'Teste 2'
    },
  ]

  @Input() selectedValue: SelectedValueInterface

  @Input() placeholder: string

  @Output() outValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _eref: ElementRef) {}

  get control() {
    if (!this.form || !this.controlName) {
      return null;
    }
    return this.form.get(this.controlName) as FormControl;
  }

  ngOnInit(): void {
    if (this.dataOptions && this.dataOptions.length > 0) {
      if (!this.selectedValue || !this.selectedValue.id) {
        this.selectedValue = this.dataOptions[0];
      }
      this.form.controls[this.controlName]?.setValue(this.selectedValue.id);
    }
  }

  onOptionSelected(event: MatSelectChange) {
    this.selectedValue = event.value;
    this.form.controls[this.controlName].setValue(this.selectedValue);
    this.outValue.emit(this.selectedValue);
    this.arrowToggle = false;
    this.showInformer = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedValue']) {
      this.setInitialValue();
    }
  }

  setInitialValue() {
    const control = this.form?.controls[this.controlName];
    if (control && this.selectedValue !== undefined) {
      control.patchValue(this.selectedValue.id, { emitEvent: false });
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.arrowToggle = false;
    }
  }

  animatedArrow() {
    this.arrowToggle = !this.arrowToggle
  }

}
