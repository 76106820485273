<base-dialog title="Edição personalizada">
   <section class="profile-cropper">
      <div class="profile-cropper__image">
         <div class="profile-cropper__image-view">
            <ng-container *ngIf="imageChangedEvent; else upload">
               <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [roundCropper]="false"
                  [aspectRatio]="16 / 9"
                  backgroundColor="#000"
                  alignImage="center"
                  (imageCropped)="croppedImage = $event.blob"
                  (imageLoaded)="isRotating = 0"
               ></image-cropper>
            </ng-container>
            <ng-template #upload>
               <input
                  class="profile-cropper__image-view-file"
                  type="file"
                  (change)="fileChangeEvent($event)"
                  #selectFile
                  [accept]="imgOnly"
               />
               <div
                  role="button"
                  class="profile-cropper__image-view-dropzone"
                  (click)="selectFile.click()"
                  dropzone
                  (fileDropped)="fileChangeEvent($event)"
               >
                  <span class="icon-upload"></span>
                  <p>clique ou arraste para carregar sua nova imagem</p>
               </div>
            </ng-template>
         </div>
      </div>
      <div
         class="profile-cropper__controls"
         [class.profile-cropper__controls--overlay]="!imageChangedEvent"
      >
         <div class="profile-cropper__controls-buttons">
            <span class="profile-cropper__controls-buttons-itens">
               <button
                  title="girar esquerda"
                  (click)="rotateLeft()"
                  class="btn-loader"
                  [class.btn-loader--start]="isRotating === 1"
               >
                  <span class="icon-ccw"></span>
               </button>
               <button
                  title="girar direita"
                  (click)="rotateRight()"
                  class="btn-loader"
                  [class.btn-loader--start]="isRotating === 2"
               >
                  <span class="icon-cw"></span>
               </button>
               <button title="espelhar verticalmente" (click)="flipVertical()">
                  <span class="icon-horizontal-flip"></span>
               </button>
               <button title="espelhar horizontalmente" (click)="flipHorizontal()">
                  <span class="icon-vertical-flip"></span>
               </button>
            </span>
            <button class="profile-cropper__controls-buttons-reset" (click)="resetImage()">
               Resetar
            </button>
         </div>

         <div class="profile-cropper__controls-sliders">
            <div class="profile-cropper__controls-sliders-slide">
               <span class="profile-cropper__controls-sliders-slide-label">
                  <label id="zoom">Zoom</label>
                  <input
                     type="number"
                     [(ngModel)]="zoom"
                     min="0"
                     max="100"
                     step="10"
                     (change)="changeZoom($event.target)"
                  />
               </span>
               <p-slider
                  [min]="0"
                  [max]="100"
                  ariaLabelledBy="zoom"
                  (onChange)="changeZoom($event)"
                  [(ngModel)]="zoom"
               />
            </div>
            <div class="profile-cropper__controls-sliders-slide">
               <span class="profile-cropper__controls-sliders-slide-label">
                  <label id="ajuste">Ajuste</label>
                  <input
                     type="number"
                     [(ngModel)]="ajustImg"
                     min="-45"
                     max="100"
                     step="10"
                     (change)="rotateImage($event.target)"
                  />
               </span>
               <p-slider
                  [min]="-45"
                  [max]="45"
                  [(ngModel)]="rotation"
                  ariaLabelledBy="ajuste"
                  [(ngModel)]="ajustImg"
                  (onChange)="rotateImage($event)"
               />
            </div>
         </div>
         <button
            class="profile-cropper__controls-send btn-loader"
            (click)="saveImage()"
            [class.btn-loader--start]="savingLoading"
         >
            Salvar
         </button>
      </div>
   </section>
</base-dialog>
