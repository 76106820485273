<base-dialog title="Adicionar vídeo">
   <form class="video-form" [formGroup]="videoFormGroup">
      <div class="video-form__controls">
         <div class="control">
            <inputs
               input_type="input"
               type="text"
               label="Título do vídeo*"
               placeholder="Título do vídeo"
               NameformControl="title"
               [submitted]="submitted"
            />
         </div>
         <div class="control">
            <editor [form]="videoFormGroup" inputControlName="description" label="Descrição*" />
         </div>
         <div class="control">
            <bluewhale-select
               [dataOptions]="dataOptionsCategories"
               [form]="videoFormGroup"
               [controlName]="'category'"
               placeholder="Categoria..."
               label="Selecione a categoria*"
               type="default"
            />
         </div>
         <div class="control grid">
            <button
               class="upload-button btn-loader"
               (click)="openCropper()"
               title="Subir thumbnail"
               type="button"
            >
               Upload image <i class="icon-plus" style="font-size: 16px"></i>
            </button>
            <div class="preview" *ngIf="imagePreview; else previewDefault">
               <img class="preview-img" [src]="imagePreview" alt="Preview" />
               <button type="button" class="clear-button" (click)="clearImage()">
                  <i class="icon-cancel" style="color: #fff"></i>
               </button>
            </div>

            <ng-template #previewDefault>
               <div class="img-preview">
                  <i style="font-size: 47px; color: #115bfb" class="icon-picture"></i>
               </div>
            </ng-template>
         </div>
         <div class="control">
            <bluewhale-radio
               [controlName]="'isPublic'"
               [options]="optionsRadio"
               [form]="videoFormGroup"
               label="Video é público?*"
            ></bluewhale-radio>
         </div>
      </div>
      <div *ngIf="data?.url; else not">
         <mux-uploader
            #mux
            id="my-uploader"
            (success)="handleUploadSuccess($event)"
            title="Upload video"
            [endpoint]="data?.url"
         >
            <button class="btn-mux" type="button" slot="file-select">Enviar</button>
         </mux-uploader>
         <mux-uploader-progress type="percentage" muxUploader="my-uploader"></mux-uploader-progress>
      </div>

      <ng-template #not>
         <p>Serviço de Upload indiponível.</p>
      </ng-template>
   </form>
   <mat-dialog-actions>
      <button
         type="submit"
         title="Enviar vídeo"
         class="video-submit btn-loader"
         [class.btn-loader--start]="isLoading"
         [disabled]="videoFormGroup.invalid"
         (click)="sendVideo()"
      >
         ENVIAR
      </button>
   </mat-dialog-actions>
</base-dialog>
