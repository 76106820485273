<div class="chart-container">
   <div class="chart-container__head">
      <p class="chart-container__head-title">
         {{ label }}
      </p>
      <div class="chart-container__head-label">
         <div class="ellipses" *ngFor="let ellipse of series">
            <div [style.background]="ellipse.color" class="ellipses-radius"></div>
            <p class="ellipses-subtitle">
               {{ ellipse.name }}
            </p>
         </div>
      </div>
   </div>
   <apx-chart
      [series]="series"
      [chart]="chart"
      [stroke]="stroke"
      [dataLabels]="dataLabels"
      [colors]="colors"
      [fill]="fill"
      [legend]="legend"
      [grid]="grid"
      [yaxis]="yAxis"
      [labels]="labels"
      #apexChart
   ></apx-chart>
   <!-- [tooltip]="tooltip" -->
</div>
