import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "@core/pages/not-found/not-found.component";
import { AuthenticatedGuard } from "@guards/authenticated.guard";
import { DisableGuardGuard } from "@guards/disable.guard";
import { RequestGuard } from "@shared/guards/request.guard";


const routes: Routes = [
   { path: "", redirectTo: "home", pathMatch: "full" },
   {
      path: "home",
      loadChildren: () => import("./core/pages/home/home.module").then(m => m.HomeModule),
      canActivate: [AuthenticatedGuard, RequestGuard],
   },
   {
      path: "users",
      loadChildren: () => import("./core/pages/users/users.module").then(m => m.UsersModule),
      canActivate: [AuthenticatedGuard, RequestGuard],
   },
   {
      path: "login",
      loadChildren: () => import("./core/pages/auth/auth.module").then(m => m.AuthModule),
      canActivate: [DisableGuardGuard, RequestGuard],
   },
   {
      path: "users/new",
      loadChildren: () =>
         import("./core/pages/new-user/new-user.module").then(m => m.NewUserModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "reports",
      loadChildren: () =>
         import("./core/pages/reports/reports.module").then(m => m.ReportsModule),
      canActivate: [AuthenticatedGuard, RequestGuard],
   },
   {
      path: "videos",
      loadChildren: () =>
         import("./core/pages/videos/videos.module").then(m => m.VideosModule),
      canActivate: [AuthenticatedGuard, RequestGuard],
   },
   {
      path: 'settings',
      canActivate: [RequestGuard],
      loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
   },
   {
      path: 'financial', loadChildren: () => import('./core/pages/financial/financial.module').then(m => m.FinancialModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      canActivate: [AuthenticatedGuard],
   },
   { path: '404', component: NotFoundComponent, data: { disabledPermission: true }},
   { path: '**', redirectTo: "404", pathMatch: "full" },
]

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
