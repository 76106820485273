import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { ELocalStorage } from "@enums/localstorage.enum";
import { Observable } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class DisableGuardGuard {
   constructor(private router: Router) {}
   canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token: string | null | undefined = localStorage.getItem(ELocalStorage.USER_TOKEN);
      if (!token) {
         return true;
      } else {
         this.router.navigate(["home"]);
      }

      return false;
   }
}
