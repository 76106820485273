/* istanbul ignore file */
import { ConnectedPosition, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
   ComponentRef,
   Directive,
   ElementRef,
   EventEmitter,
   HostListener,
   Injector,
   Input,
   Output,
   TemplateRef,
} from "@angular/core";
import { Placement } from "@enums/placementOverlay.enum";

import { OptionsMenuComponent } from "./options-menu.component";
import { POSITION_MAP } from "@helpers/constants/popover-position.helper";

@Directive({
   selector: "[menu]",
})
export class OptionsMenuDirective {
   @Input("menu") content: TemplateRef<any>;
   @Input() overlayPlacement: Placement = "top";
   @Output() toggle = new EventEmitter<boolean>();
   constructor(private overlay: Overlay, private elementRef: ElementRef) {}

   get positionStrategy(): ConnectedPosition[] {
      return [POSITION_MAP[this.overlayPlacement]]
   }

   @HostListener("click")
   click() {
      this.toggle.emit();
      const overlayRef = this.overlay.create({
         hasBackdrop: true,
         backdropClass: "menu-overlay",
         scrollStrategy: this.overlay.scrollStrategies.close(),
         panelClass: "menu",
         positionStrategy: this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([...this.positionStrategy]),
      });

      const component = new ComponentPortal(OptionsMenuComponent, null);
      const ref: ComponentRef<OptionsMenuComponent> = overlayRef.attach(component);
      ref.instance.content = this.content;
      ref.instance.setClose.subscribe(_ => {
         overlayRef.dispose();
         this.toggle.emit();
      });
      overlayRef.backdropClick().subscribe(_ => {
         overlayRef.dispose();
         this.toggle.emit();
      });
   }
}
