import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { TooltipComponent, TooltipDirective } from "./tooltip.directive";

@NgModule({
   declarations: [TooltipDirective, TooltipComponent],
   imports: [CommonModule],
   exports: [TooltipDirective, TooltipComponent],
})
export class TooltipModule {}
